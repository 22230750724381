import React from 'react'
import { Box, Container, Divider, List, Typography } from '@mui/material'
import Layout from '../components/layout'
import { graphql } from 'gatsby'
import { useSiteMetadata } from '../hooks/use-site-metadata'
import BlogListItem from '../components/blog-list-item'

const BlogPage = ({ data }: any) => {
    const {
        allBlogJson: { edges: allBlogEdges },
        allBlogCategory: { distinct: categories },
    } = data

    const {
        site: {
            siteMetadata: { siteUrl, siteTitle },
        },
    } = useSiteMetadata()

    const blogs = allBlogEdges.map((item: any) => {
        return {
            ...item.node,
        }
    })

    const categoryBlogs = categories.map((category: any) => {
        return { category, blogs: blogs.filter((blog: any) => blog.category === category) }
    })

    const title = `ブログ一覧 | ${siteTitle}`
    const description = `ブログ一覧`
    return (
        <Layout title={title} description={description}>
            <Container sx={{ pt: { xs: 3, sm: 6 }, maxWidth: { xs: '100%', sm: '90%' } }}>
                <Typography variant="h1" sx={{ mb: 2, fontWeight: 'bold' }}>
                    ブログ一覧
                </Typography>
                {categoryBlogs.map((categoryBlog: any, index: number) => (
                    <Box key={index}>
                        <Typography variant="h2" sx={{ mt: 4, fontWeight: 'bold' }}>
                            {categoryBlog.category}
                        </Typography>
                        <List>
                            {categoryBlog.blogs.map((blog: any, index: number) => {
                                return (
                                    <Box key={index}>
                                        {!!index && <Divider />}
                                        <BlogListItem blog={blog} />
                                    </Box>
                                )
                            })}
                        </List>
                    </Box>
                ))}
            </Container>
        </Layout>
    )
}

export const query = graphql`
    query BlogQuery {
        allBlogJson(sort: { order: ASC, fields: category }) {
            edges {
                node {
                    id
                    blog_id
                    site
                    blog_url
                    feed_url
                    category
                    tags
                    image {
                        childImageSharp {
                            gatsbyImageData(
                                layout: FIXED
                                width: 96
                                height: 54
                                transformOptions: { cropFocus: CENTER }
                                quality: 90
                            )
                        }
                    }
                }
            }
        }
        allBlogCategory: allBlogJson(sort: { order: ASC, fields: category }) {
            distinct(field: category)
        }
    }
`

export default BlogPage
